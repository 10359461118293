import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";

function PlanTrip() {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>How It Works</h3>
             <h2> </h2>
              {/* <h2 style={{ zIndex: 100000 }}>Booking Your Ride's Easily</h2> */}
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>Easy Booking Process</h3>
                <p>
                With just a few clicks, you can quickly reserve your taxi and be on your way to your destination. 
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Personalized Ride Tracking</h3>
                <p>
                Easily monitor your ride's progress with personalized tracking features tailored to your preferences. 
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Instant Driver Chat & Call</h3>
                <p>
                Enhance your ride experience with seamless and professional interaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
