import Footer from "../components/Footer";

function Privacy() {
  return (
    <div className="mainDiv">
      <div className="containerDiv">
        <h1 className="header">PRIVACY POLICY</h1>
        <p>
          Your privacy matters to Enseedling Private Limited
          (the “<strong>Company”, “we”, Intacsy, “us”</strong> or <strong>“our”</strong>).
          <br /> <br />
          This Privacy Policy (“<strong>Policy</strong>”) describes the policies and procedures
          on the collection, use, processing, storage, retrieval, disclosure,
          transfer and protection of your information, including personal
          information and sensitive personal data or information
          <strong>(“Information”)</strong>, that Intacsy may receive through your online access,
          interaction or use, of the Intacsy mobile applications <strong>(“Intacsy App”)</strong>
          or through your
          offline interaction with us including through mails, phones, in
          person, etc., or while availing our Services.
          <br /> <br />
          The terms <strong>“you” </strong> and <strong>“your”</strong> refer to a Captain (defined below), a
          Customer (defined below), a Vendor Partner (defined below) or any
          other user of the Intacsy Platform and / or availing the Services
          (defined below).
          <br /> <br />
          The term <strong>“Services”</strong> refers to any services offered by Intacsy in
          accordance with the terms and conditions applicable to you (and
          available on the Intacsy Platform) whether on the Intacsy Platform or
          otherwise.
          <br /> <br />
          Capital terms not defined herein have the meaning assigned to them in
          the terms and conditions applicable to you and available on Intacsy
          Platform.
        </p>
        <p>
          Please read this Policy before using the Intacsy Platform or submitting
          any Information to Intacsy. This Policy is a part of and incorporated
          within, and is to be read along with, the terms and conditions
          applicable to the users of the Intacsy App available on the Intacsy
          Platform.
        </p>

        <ol className="olMainCard">
          <li className="liHeadText">USER ACCEPTANCE</li>

          <p>
            By accessing or using the Intacsy Platform or the Services, you agree
            and consent to this Policy, along with any amendments made by the
            Company at its sole discretion and posted on the Intacsy Platform
            from time to time.
          </p>
          <p>
            Any collection, processing, retrieval, transfer, use, storage,
            disclosure and protection of your Information will be in accordance
            with this Policy and applicable laws including but not limited to
            Information Technology Act, 2000, and the rules framed thereunder
            <strong>(“Applicable Laws”)</strong>. If you do not agree with the Policy, please do
            not use or access the Intacsy Platform.
          </p>
          <p>You hereby represent to Intacsy that:</p>

          <ol type="a">
            <li>
              The Information you provide to Intacsy from time to time, is and
              will be authentic, correct, current and updated and you have all
              the rights, permissions and consents as may be required to provide
              such Information to Intacsy.
            </li>
            <li>
              Your providing of the Information as well as Intacsy's consequent
              storage, collection, usage, transfer, access, or processing of
              such Information will not be in violation of any agreement,
              Applicable Laws, charter documents, judgments, orders and decrees.
            </li>
            <li>
              If you disclose to us any Information relating to other people,
              you represent that you have the authority to do so and to permit
              us to use such Information in accordance with this Policy.
            </li>
          </ol>

          <li className="liHeadText">DEFINITIONS</li>

          <p>
            Unless otherwise provided in this Policy, the terms capitalized in
            the Policy shall have the meaning as provided hereunder:
          </p>

          <ol type="a">
            <li>
              “Captains” means independent third-party vehicle operators or
              riders who wish to offer services on the Intacsy Platform to a
              user/Customer.
            </li>
            <li>
              “Co-branded Services” shall have the meaning assigned to the term
              in paragraph 5(c) hereto.
            </li>
            <li>
              “Customer” shall mean a person who wishes to receive services of
              the Captains or Vendor Partners on the Intacsy Platform.{" "}
            </li>
            <li>
              “Device” shall mean computer, mobile or other device used to
              access the Services.
            </li>
            <li>
              “Device Identifier” shall mean IP address or other unique
              identifier of the Device.
            </li>
            <li>
              “Promotion” shall mean any contest and other promotions offered by
              us.
            </li>
            <li>
              “Personal Information” shall mean such categories of information
              that could reasonably be used to identify you personally,
              including your name, e-mail address, and mobile number.
            </li>
            <li>“TPSP” shall mean a third-party service provider.</li>
            <li>
              {" "}
              “Usage Information” shall have the meaning assigned to the term in
              paragraph 3(II) hereto.
            </li>
            <li>
              “Vendor Partner” shall mean third party providers who offers
              2-wheeler rental services on Intacsy Platform.
            </li>
          </ol>

          <li className="liHeadText">WHAT INFORMATION DO WE COLLECT?</li>

          <ol type="I">
            <li>INFORMATION YOU PROVIDE TO US</li>
            <ol type="a">
              <li>
                Personal Information: We may ask you to provide certain Personal
                Information to us. We may collect this information through
                various means and in various places for the provision of
                Services, including account registration forms, contact us
                forms, or when you otherwise interact with us. When you sign up
                to use the Services, you create a user profile. We shall ask you
                to provide only such Personal Information which is for lawful
                purpose connected with our Services and necessary to be
                collected by us for such purpose.
              </li>
              <li>The Information you provide to us includes the following:</li>

              <ul className="listSquareType">
                <li>
                  Account Information: Create or update your Intacsy account
                  which may include your email address, name, address, mobile
                  number, gender, date of birth, photograph, login name,
                  password, banking or payment related information (as permitted
                  by Applicable Laws), etc.
                </li>
                <li>
                  Saved Information: While you use our Services, we may collect
                  and store Information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your phone number, address,
                  email address, billing information, emergency contact
                  information, etc.
                </li>
                <li>
                  Verification Information: If you are a Vendor Partner or
                  Captain, we may collect location details, profile picture,
                  call and SMS details, copies of government issued
                  identification documents such as Aadhaar, Permanent Account
                  Number, etc., license details, and other details (KYC),
                  vehicle related documents such as, certificate of
                  registration, permit of vehicle, certificate of fitness,
                  insurance, pollution certificate etc., user settings, and such
                  other documents which evidence the health or fitness of the
                  vehicle to provide Services on the Intacsy Platform from time
                  to time. If you are a Captain we may also require you to
                  capture your real time self-clicked images (selfies) and
                  upload such selfies on the Intacsy Platform from time to time
                  to verify your identity.
                </li>
                <li>
                  Background check Information: We collect background check and
                  identity verification information of the Captains and Vendor
                  Partners. This may include collection of Information such as
                  driver history or criminal records (where permitted by law),
                  and right to work. This information may also be collected by
                  TPSP on the Company’s behalf.
                </li>
                <li>
                  Other Apps: In case you sign up as a Captain or a Vendor
                  Partner, enable features that require Intacsy’s access to other
                  applications on your Device aiming to prevent and detect fraud
                  towards the Customers.
                </li>
                <li>
                  Other Information: We collect additional Information you
                  provide when you correspond with Intacsy for customer support
                  or report problems for troubleshooting. We also collect
                  Information that you may submit electronically such as when
                  you use in-app messaging, post on any message boards, provide
                  ratings, reviews, or comments. In case you refer a friend, we
                  may also collect, store, and use the name and contact
                  information of your friend to promote our Services.
                </li>
              </ul>
            </ol>

            <li>INFORMATION WE COLLECT AS YOU ACCESS AND USE Intacsy APP</li>
            <ul className="listSquareType">
              <li>
                Transaction Information: We collect transaction Information such
                as trip details, pick-up and drop-off addresses, distance
                traveled, payment transaction information (subject to Applicable
                Laws), etc.
              </li>

              <li>Location data:</li>
              <ul className="listSquareType">
                <li>
                  Captains and Vendor Partners: Intacsy collects location data
                  when the Intacsy App is running in the foreground (app is open
                  and on-screen) or background (app is not in use) in your
                  mobile device. For Captains, Intacsy collects location data
                  only when the Captains have enabled the icon “On-Duty” on the
                  Intacsy App.
                </li>
                <li>
                  Customers: We collect precise or approximate location data
                  from your Device if you enable us to do so. Intacsy collects
                  this data from the time a Service is requested until it is
                  finished, and any time the app is running in the foreground of
                  your Device. We use this data to enhance your use of Intacsy
                  App, including to improve pick-up locations, enable safety
                  features, and prevent and detect fraud. Even if you have not
                  enabled us to collect location data from your Device, Intacsy
                  collects the Captains’ location data collected during a trip,
                  and links such location data with your account. This enables
                  us to offer services to you, such as receipt generation and
                  customer support, and enable safety of the Customers.
                </li>
              </ul>
              <li>
                Usage Information: We, our TPSP and the Vendor Partner may use a
                variety of technologies that automatically (or passively)
                collect certain Information whenever you visit or interact with
                the Intacsy Platform for obtaining the Services (“Usage
                Information”). This Usage Information may include the browser
                that you are using, the URL that referred you to our Services,
                all of the areas within our Services that you visit, and the
                time of day, searches and search results, or usage behavior on
                the Intacsy App, etc.
              </li>
              <li>
                Health related Information: If you are a Captain, we may collect
                certain health related Information and declarations such as body
                temperature, symptoms, vaccination status and other health
                conditions.
              </li>
              <li>
                Device Information: We collect Information by ourselves or
                through integration with third-party applications which consists
                of technical information and aggregated usage information, and
                may contain, among other things, Device Identifier of your
                Device, your preferred language and country site, manufacturer,
                software, and model of your Device, Device type, operating
                systems and versions, your geolocation, mobile network data,
                screens you have visited, your touch gestures performed in your
                Intacsy App, your scrolling activity, and any other actions you
                have performed during your use of Intacsy App, etc., to enhance
                user interface and experience on Intacsy Platform, facilitate the
                provision of software updates, product support and other
                services to you, etc. Any sensitive information about other
                programs that you are running on your Device, passwords, and
                activity across other applications are not collected and all the
                sensitive information are masked.
              </li>

              <ul className="listSquareType">
                <li>
                  SMS/Text Messages: We may collect data from SMS/ text messages
                  upon receiving Device access permissions from you for the
                  purposes of (i) issuing and receiving one-time passwords and
                  other device verification, and (ii) automatically filling
                  verification details during financial transactions, either
                  through us or a TPSP, in accordance with Applicable Laws. We
                  do not share or transfer SMS/ text message data to any third
                  party other than as provided under this Policy.
                </li>
              </ul>
              <li>
                Call details: If you are a Vendor Partner or a Captain, we will,
                additionally, record your calls with us made from the Device
                used to provide Services and related call details.
              </li>
              <li>
                Other Information: We collect Information about how you interact
                with the Intacsy App and any of our web sites to which the Intacsy
                App links, such as how many times you use a specific part of the
                Intacsy App over a given time period, the amount of time you
                spend using the Intacsy App , how often you use the Intacsy App,
                actions you take in the Intacsy App and how you engage with the
                Intacsy App, etc.
              </li>
              <li>
                Cookies: Usage Information may be collected using a cookie. If
                you do not want information to be collected through the use of
                cookies, your browser / app settings allows you to deny or
                accept the use of cookies. Cookies can be disabled or controlled
                by setting a preference within your web browser or on your
                Device. If you choose to disable cookies or flash cookies on
                your Device, some features of the Services may not function
                properly or we may not be able to customize the delivery of
                information to you. The Company cannot control the use of
                cookies (or the resulting information) by third parties, and use
                of third-party cookies is not covered by our Policy.
              </li>
            </ul>

            <li>INFORMATION THIRD PARTIES PROVIDE ABOUT YOU</li>
            <p>
              We may, from time to time, collect Information about you through
              Intacsy Platform or while availing the Services and collect
              Information from our affiliates or third parties / TPSPs such as
              technical sub-contractors, business partners, analytics providers,
              search information providers, payment service providers, etc., and
              also from publicly available sources such as commercially
              available marketing lists, social networks and other related
              media.
            </p>
          </ol>

          <li className="liHeadText">USE OF INFORMATION COLLECTED</li>
          <p>
            Our primary goal in collecting your Information is to provide you
            with an enhanced experience when using the Services. We may use your
            Information we collect in accordance with this Policy for the
            following purposes:
          </p>

          <ol type="i">
            <li>To enable you to access the Intacsy Platform;</li>
            <li>
              To verify your identity and/ or your capacity, under applicable
              law, to provide Services through the Intacsy Platform;
            </li>
            <li>
              To closely monitor which features of the Services are used most,
              to allow you to view your trip history, rate trips or bookings,
              and to determine which features need to be improved for enhanced
              user experience, including usage patterns and geographic locations
              to determine where we should offer or focus services, features
              and/or resources;
            </li>
            <li>
              To send you a welcome email/SMS to verify your username and
              password;
            </li>
            <li>
              To provide you the correct app version depending on your Device
              type, for troubleshooting and in some cases, marketing purposes;
            </li>
            <li>
              o help diagnose problems with our computer server, and to
              administer Intacsy Platform;
            </li>
            <li>
              To send you strictly service-related announcements on rare
              occasions when it is necessary to do so. For instance, if our
              Services are temporarily suspended for maintenance, we might send
              you an email. If you do not wish to receive them, you have the
              option to deactivate your account;
            </li>
            <li>
              To prevent, discover and investigate violations of this Policy or
              any applicable terms of service or terms of use;
            </li>
            <li>
              To identify and/or detect security breaches or attacks, errors,
              fraud, money laundering, abuse and other criminal activities, and
              investigating and taking appropriate remedial action;
            </li>
            <li>
              We provide some of your Personal Information (such as your name,
              pick up address, contact number) to the Captain or the Vendor
              Partner who accepts your request for Services so that the Captain
              or the Vendor Partner may contact and find you;
            </li>
            <li>
              If you are a Customer, we use geo-location information for various
              purposes, including for you to be able to view the Captains /
              Vendor Partners in your area that are close to your location to
              provide services to you, to automatically fetch your location when
              you open the Intacsy App, for the Captains to identify the pickup
              location and to allow you (if you choose through any features we
              may provide) to share this information with other people for your
              safety;
            </li>
            <li>
              If you are a Captain, we use the geo-location information for
              (only when the Captain activates the icon “On-Duty” on the Intacsy
              App) you to receive orders close to your location even when the
              Intacsy App is not in use or closed, to enable the Customers to
              track the distance of the Captains from their location for pick
              up, to share the exact location for the safety of the Customers,
              etc.;
            </li>
            <li>
              If you are a Captain, we may share your name, vehicle number,
              driving license number, phone number and/or profile picture (if
              applicable), tracking details with our Customers to provide them
              the Services;
            </li>
            <li>
              We may use your Personal Information or Usage Information that we
              collect about you: (a) to provide you with information or services
              or process transactions that you have requested or agreed to
              receive including to send you electronic newsletters, or to
              provide you with special offers or promotional materials on behalf
              of us or third parties; (b) to enable you to participate in a
              variety of the Services’ features such as online or mobile entry
              sweepstakes, contests or other promotions; (c) to contact you with
              regard to your use of the Services and, in our discretion, changes
              to the Services and/or the Services’ policies; (d) for internal
              business purposes; (e) for inclusion in our data analytics; and
              (f) for purposes disclosed at the time you provide your
              Information or as otherwise set forth in this Policy;
            </li>
            <li>
              To enhance your user experience in relation to the Intacsy App or
              the Services, including customisation / personalization of the
              Intacsy App or the Services;
            </li>
            <li>
              To provide relevant offers or rewards to you, based on your
              consumption patterns;
            </li>
            <li>
              To enforce our terms and conditions and this Policy, and resolve
              any disputes;
            </li>
            <li>
              To provide functionality, analyse performance, fix errors, bugs,
              and improve the usability and effectiveness of the Intacsy
              Platform;
            </li>
            <li>
              To comply with Applicable Laws or requests received from
              regulators, government, law enforcement or judicial authorities
              under Applicable Laws or our contract with a third party;
            </li>
            <li>
              To carry out our obligations and enforcing rights arising from any
              contracts between us;
            </li>
            <li>
              To disclose to affiliates, our and their employees, agents and
              representatives on a need-to-know basis to facilitate provision of
              Services;
            </li>
            <li>
              To deliver any administrative notices, alerts, advice,
              notifications and communication relevant to your use of the
              Services, through social media (including WhatsApp), SMS and other
              media;
            </li>
            <li>
              If you sign up to use our Services as an employee or as a
              stakeholder of a third party with whom the Company has an
              arrangement and has offered discount coupons/ or extended certain
              promotional offers, the Company may share any information provided
              by you with such third party to be utilised by them for limited
              internal business purposes only; and/ or
            </li>
            <li>
              To fulfil any other purpose for which you provide us the
              Information and/or for any other purpose with your consent. Please
              note, we do not use the information collected from you for
              targeted advertising.
            </li>
          </ol>
          <li className="liHeadText">
            HOW AND WHEN DO WE DISCLOSE INFORMATION TO THIRD PARTIES
          </li>
          <p>
            We do not sell, share, rent or trade the information we have
            collected about you, other than as disclosed within this Policy or
            at the time you provide your Information. Following are the
            situations when Information may be shared:
          </p>
          <ol type="a">
            <li>
              <p>WHEN YOU AGREE TO SHARE INFORMATION WITH THIRD PARTIES:</p>
              <p>
                You may opt to receive information and/or marketing offers
                directly from third parties when you access third party links on
                the Intacsy App. If you do agree to have your Personal
                Information shared, your Personal Information will be disclosed
                to such third parties and all Information you disclose will be
                subject to the privacy policy and practices of such third
                parties. We are not responsible for the privacy policies and
                practices of such third parties and, therefore, you should
                review the privacy policies and practices of such third parties
                prior to agreeing to receive such information from them. If you
                later decide that you no longer want to receive communication
                from a third party, you will need to contact that third party
                directly.
              </p>
            </li>

            <li>
              <p>THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF</p>
              <p>
                We may share the Information you provide with our TPSPs,
                business partners, and agents. Please refer to such
                third-party’s privacy policy for more details before using their
                services on the Intacsy App.
              </p>
              <p>
                We use TPSPs to facilitate our Services, provide or perform
                certain aspects of the Services on our behalf – such as host the
                Services, design and/or operate the Services’ features, track
                the Services’ analytics, process payments, engage in anti-fraud
                and security measures, perform background and identity
                verification, run criminal record checks, provide customer
                support, provide geo-location information to Captains / Vendor
                Partners, enable us to send you special offers, host our job
                application form, perform technical services (e.g., without
                limitation, maintenance services, database management, web
                analytics and improvement of the Services‘ features), or perform
                other administrative services. These third parties will have
                access to Information, including Personal Information to only
                carry out the services they are performing for you or for us. We
                will require each of these TPSPs to ensure the same level of
                data protection as us and impose contractual obligations not to
                disclose or use Personal Information for any other purpose.
              </p>
              <p>TPSPs providing analytics related services may set and access their own cookies, web beacons and embedded scripts on your Device and they may otherwise collect or have access to Information about you.</p>
              <p>We use a third-party hosting provider who hosts our support section of our website. Information collected within this section of our website by such TPSP is governed by our Policy.</p>
              <li>
                <p>CO-BRANDED SERVICES</p>
                <p>Certain aspects of the Services may be provided to you in association with third parties (“<strong>Co-Branded Services</strong>”) such as credit houses, loan providers, sponsors and charities, and may require you to disclose Information including Personal Information to them. Such Co-Branded Services will identify the third party. If you elect to register for products and/or services through the Co-Branded Services, you shall have deemed to have consented to providing your Information to both us and the third party. Further, if you sign-in to a Co-Branded Service with a username and password obtained through our Services, your Personal Information may be disclosed to the identified third parties for that Co-Branded Service and will be subject to their privacy policies and practices.</p>
              </li>
              <li>
                <p>CONTESTS AND PROMOTIONS</p>
                <p>We may offer Promotions through the Services that may require registration. By participating in a Promotion, you are agreeing to the official rules that govern that Promotion, which may contain specific requirements of you, including, allowing the sponsor of the Promotion to use your name, voice and/or likeness in advertising or marketing associated with the Promotion. If you choose to enter a Promotion, you agree that your Personal Information may be disclosed to third parties or the public in connection with the administration of such Promotion, including, in connection with winner selection, prize fulfillment, and as required by law or permitted by the Promotion’s official rules, such as on a winners list.</p>
              </li>
              <li>
                <p>ADMINISTRATIVE AND LEGAL REASONS</p>
                <p>We cooperate with Government and law enforcement officials and private parties to enforce and comply with the Applicable Laws. Thus, we may access, use, preserve, transfer and disclose your information (including Personal Information, IP address, Device Information or geo-location data), to government or law enforcement officials or private parties as we reasonably determine is necessary and appropriate: (i) to satisfy any Applicable Law, regulation, subpoenas, Governmental requests or legal process; (ii) to protect and/or defend the terms and conditions applicable to use of the Intacsy App or the Services, including investigation of potential violations thereof; (iii) to protect the safety, rights, property or security of the Company, our Services or any third party; (iv) to protect the safety of the public for any reason; (v) to detect, prevent or otherwise address fraud, security or technical issues; and /or (vi) to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity.</p>
              </li>
              <li>
                <p>AFFILIATES AND BUSINESS TRANSFER</p>
                <p>We may share your Information, including your Personal Information and Usage Information with our parent, subsidiaries and affiliates for internal reasons, including business and operational purposes. We also reserve the right to disclose and transfer all such information: (i) to a subsequent owner, co-owner or operator of the Services or applicable database; or (ii) in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our membership interests and/or assets or other corporate change, including, during the course of any due diligence process.</p>
              </li>
              <li>
                <p>MARKET STUDY AND OTHER BENEFITS</p>
                <p>We may share your information, including your Personal Information and Usage Information with third parties for any purpose, including but not limited to undertaking market research/ study, conduct data analysis, determine and customize product or service offerings, to improve the products or Services or to make any other benefits/products/ services available to you.</p>
              </li>
            </li>
          </ol>
          <li className="liHeadText">THIRD PARTY CONTENT AND LINKS TO THIRD PARTY SERVICES
          </li>
          <p>The Services may contain content that is supplied by a third party, and those third parties may collect website usage information and your Device Identifier when web pages from any online or mobile Services are served to your browser. In addition, when you are using the Services, you may be directed to other sites or applications that are operated and controlled by third parties that we do not control, in which case our Policy will no longer apply. We are not responsible for the privacy practices employed by any of these third parties. For example, if you click on a banner advertisement, the click may take you away from Intacsy App onto a different web site. These other web sites may send their own cookies to you, independently collect data or solicit Information and may or may not have their own published privacy policies.</p>
          <p>Information (including Personal Information) may be collected by third-parties if there is content from the Intacsy App that you specifically and knowingly upload to, share with or transmit to an email recipient, online community, website, or to the public, e.g. uploaded photos, posted reviews or comments, or information about you or your ride or booking that you choose to share with others through features which may be provided on our Services. This uploaded, shared, or transmitted content will also be subject to the privacy policy of the email, online community website, social media or other platform to which you upload, share or transmit the content. We are not responsible for the privacy practices employed by any of these third parties.</p>
          <p>Our online and mobile Services may include social media features, such as the Facebook Like button, and widgets such as a “Share This” button, or interactive mini-programs that run on Intacsy App. These features may collect Information including your IP address, photograph, which page you are visiting on our online or mobile Services, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our online Services and/or Intacsy Platform. Your interactions with these features and widgets are governed by the privacy policy of the company providing them and we will not be responsible or liable for any acts or omissions of such third parties.</p>
          <p>In particular, remember that certain third-parties may be located in or have facilities that are located in a different jurisdiction, hence, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which such service provider is, or its facilities are located. We encourage you to note when you leave web pages or links controlled by Intacsy App/ Services and to read the privacy statements of all third party web sites or applications before submitting any Information to such third parties. We will not be liable for any acts or omissions of the third-party service providers.</p>

          <li className="liHeadText">INFORMATION COLLECTED BY CAPTAINS AND VENDOR PARTNERS</li>
          <p>This Policy does not cover the usage of any information about you which is obtained by the Captains or Vendor Partners while providing you a Transportation, Delivery, Package or Rentals Services, or otherwise.</p>

          <li className="liHeadText">CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</li>
          <ol type="a">
            <li>You are responsible for maintaining the accuracy of the Information you submit to us, such as your contact information provided as part of account registration.</li>
            <li>If your Personal Information or Information you provide to us changes, or if you no longer desire our Services, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through the email address mentioned on Intacsy Platform, or contacting the Grievance Officer. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable.</li>
            <li>You may also cancel or modify the communications that you have elected to receive from Intacsy by following the instructions contained within an e-mail or by logging into your user account and changing your communication preferences.</li>
            <li>If upon modifying or changing the Information earlier provided to Us, we find it difficult to provide access to our Services to you due to insufficiency/ inaccuracy of the Information, we may, in our sole discretion terminate your access to the Services by providing you a written notice to this effect on your registered email address.</li>
            <li>If you wish to cancel your account or request that we no longer use your Information to provide you services, contact us through email address mentioned on the trip bill received, the Intacsy Platform or the Grievance Officer mentioned in this Policy. Please note, we may not be able to provide some or all of the Services in case you disable access to any of your Information as described under this Policy.</li>
            <li>We will retain your Information including Personal Information and Usage Information (including geo-location) for as long as your account with the Services is active and as needed to provide you services. Even after your account is terminated, we will retain some of your Information including Personal Information and Usage Information (including geo-location, trip history, and transaction history) for such statutory time-period as needed to comply with Applicable Laws, to resolve disputes, conclude any activities related to cancellation of an account, investigate, or prevent fraud and other inappropriate activity related to your account, to enforce our agreements, or for other business reasons, etc. After completion of such statutory period, your data may either be deleted from our database or be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes.</li>
          </ol>
          <li className="liHeadText">SECURITY</li>
          <p>The Information we collect is securely stored within our databases, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls and SSL (Secure Socket Layers) for protecting your Information. However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that Information you supply won't be intercepted while being transmitted to us over the Internet or wireless communication, and any Information you transmit to us, you do at your own risk. We recommend that you not disclose your password to anyone.</p>

          <li className="liHeadText">INFORMATION OF CHILDREN</li>
          <p>We do not knowingly solicit or collect Information from children under the age of 18 years. Use of the Intacsy App is only available for persons who can enter into a legally binding contract under Applicable Laws.</p>

          {/* <li className="liHeadText">GRIEVANCE OFFICER</li>
          <p>If you would like to ask about, make a request relating to, or complain about how We process your information, please contact or email our grievance officer, at one of the addresses below. Our grievance officer will attempt to expeditiously redress your grievances.</p>
          <p>For any request, complain, feedback or grievances, please contact: -</p>
          <ol>
            <li>Name – </li>
            <li>Email details – grievances@Intacsy.com</li>
          </ol> */}

          <li className="liHeadText">CHANGES TO THE PRIVACY POLICY</li>
          <p>We reserve the right to update / modify, from time to time, this Policy to reflect changes to our Information practices. Any changes will be effective immediately upon the posting of the revised Policy on the Intacsy Platform. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on the Intacsy App prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices. Your use of the Intacsy App or availing the Services after an updated Policy becomes effective will indicate your acceptance of the updated Policy.</p>
        </ol>
      </div>

      <style>{`
          .header{
            text-align : center;
            font-size : 3rem;
            margin : 0 0 2rem 0;
          }
          .mainDiv{
            display : flex;
            justify-content : center;
          }
        .containerDiv{
          font-size : 1.4rem;
          font-family : Rubik;
          width : 70vw;
          margin : 5rem;
        }
        .containerDiv .olMainCard{
          margin-left : 2rem;
        }
        .containerDiv .liHeadText{
          margin : 4.5rem 0 1.5rem 0;
        }
        .listSquareType{
          list-style-type : square;
        }
        .containerDiv li{
          margin : 1.2rem;
        }

        @media only screen and (max-width: 600px) {
          .containerDiv{
            width : 100vw;
            margin : 6rem 1.5rem;
          }
        }

        @media only screen and (min-width: 600px) {
          .containerDiv{
            font-size : 1.8rem;
            width : 100vw;
            margin : 10rem 5rem;
          }
          .containerDiv li{
            margin : 1.5rem;
          }
        }
        @media only screen and (min-width: 992px) {
          .containerDiv{
            width : 70vw;
            font-size : 1.4rem;
            margin : 5rem;
          }
        }
            
        `}</style>
    </div>
  );
}

export default Privacy;
