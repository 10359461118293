import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id  ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id  ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id  ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Intacsy
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. How do I book a taxi ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                 You can book a taxi through our mobile app, or by calling our customer service hotline.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. What information do I need to provide when booking a taxi ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  We require your pick-up location, drop-off location, preferred time of pick-up, contact information, and any special requests.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3.	Can I book a taxi for a specific time in the future ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Yes, you can schedule a taxi for a future date and time through our app.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4.	 How do I know if my booking is confirmed ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                  Once your booking is confirmed, you will receive a confirmation message via the booking platform you used (app, or SMS).
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__question ${getClassQuestion("q5")}`}
                >
                  <p>5.	Are your drivers licensed and insured ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__answer ${getClassAnswer("q5")}`}
                >
                  Yes, all our drivers are licensed and insured, and they undergo background checks to ensure passenger safety.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__question ${getClassQuestion("q6")}`}
                >
                  <p>6.	Do you operate 24/7 ?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__answer ${getClassAnswer("q6")}`}
                >
                  Yes, we operate 24/7, including holidays and weekends. You can book a taxi anytime you need one.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
