import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/plan/icon1.png";
import Box3 from "../images/chooseUs/icon3.png";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Why Choose Us</h4>
                <h2>Best valued service you will ever find</h2>
                <p>
                We understand that every customer is unique, which is why we strive to provide a personalized experience tailored to your individual preferences, needs, and requirements.
We prioritize the security of your personal information and use advanced encryption technology to safeguard your data, providing peace of mind that your privacy is protected when using our services.

                </p>
                {/* <a href="#home">
                  Find Details &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a> */}
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Reliability</h4>
                    <p>
                    We prioritize punctuality and reliability, ensuring that you reach your destination on time, every time. With our efficient booking system and dedicated drivers, you can trust us to be there when you need us.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Easy Booking</h4>
                    <p>
                    Our user-friendly booking platform makes it quick and easy to reserve your ride. Simply input your details, choose your vehicle preference, and confirm your booking – it's that simple!
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Local Expertise</h4>
                    <p>
                    Our drivers are not just skilled professionals but also local experts. They know the area inside out, ensuring the most efficient routes to avoid traffic and get you to your destination swiftly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
